export enum dataFields {
  GENE = 'genes',
  GENE_SYMBOL = 'Gene_Symbol',
  AVERAGE_SCORE = 'avg_scores',
  SYNGO = 'syngo',
  SYNDB = 'syndb',
  SYNSYSNET = 'synsysnet',
  CORTEX = 'cortex',
  STRIATUM = 'striatum',
  FETAL = 'fetal',
  NGN2 = 'ngn2',
  ALL_SUM = 'All_Sum',
  SYNSIG = 'SynSig',
  SYNAPSE_STATUS = 'Synapse_Status',
  SYNAPSE_PERCENTILE = 'Synapse_Percentile',
  TRAINING = 'training_positive_examples',
  NAME = 'name',
  RECEPTOR_CHANNEL = 'Receptor_Channel',
  KINASE_PHOSPHATASE = 'Kinase_Phosphatase',
  UBIQUITIN_E3 = 'Ubiquitin_E3',
  VESICLE_TRANSPORTERS = 'Vesicle_Transporters',
  GTP_ATP_REGULATORS = 'GTP_ATP_regulators',
  NUCLEIC_ACID_BINDING = 'Nucleic_Acid_Binding',
  TRANSLATION = 'Translation',
  MEMBRANE_CELL_ADHESION = 'Membrane_Cell_Adhesion',
  OTHER_REGULATORS = 'Other_Regulators',
  SCAFFOLDS_ADAPTORS = 'Scaffolds_Adaptors',
  CYTOSKELETAL = 'Cytoskeletal',
  CALCIUM_ION_BINDING = 'Calcium_Ion_Binding',
  OTHER_ENZYMES = 'Other_Enzymes',
  OTHER_PROTEIN_BINDERS = 'Other_protein_binders',
  UNKNOWN_FUNCTIONS = 'unknown_functions',
  MF_TERMS = 'MF_Terms',
  FUNCTION_TOTAL = 'Function_Total',
  HGNC_ACCESSION = 'HGNC_Accession'
}

export enum dataFieldNames {
  SYNGO = 'SynGO',
  SYNDB = 'SynDB',
  SYNSYSNET = 'SynSysNet',
  CORTEX = 'Adult mouse cortex',
  STRIATUM = 'Adult mouse striatum',
  FETAL = 'Human fetal brain',
  NGN2 = 'Human iPSC',
  SYNAPSE_STATUS = 'Status',
  SYNAPSE_PERCENTILE = 'Synapse likelihood score percentile',
  RECEPTOR_CHANNEL = 'Receptor/Channel',
  KINASE_PHOSPHATASE = 'Kinase/Phosphatase',
  UBIQUITIN_E3 = 'Ubiquitin/E3',
  VESICLE_TRANSPORTERS = 'Vesicle/Transporters',
  GTP_ATP_REGULATORS = 'GTP/ATP regulators',
  NUCLEIC_ACID_BINDING = 'Nucleic acid binding',
  TRANSLATION = 'Translation',
  MEMBRANE_CELL_ADHESION = 'Membrane/Cell adhesion',
  OTHER_REGULATORS = 'Other regulators',
  SCAFFOLDS_ADAPTORS = 'Scaffolds/Adaptors',
  CYTOSKELETAL = 'Cytoskeletal',
  CALCIUM_ION_BINDING = 'Calcium ion binding',
  OTHER_ENZYMES = 'Other enzymes',
  OTHER_PROTEIN_BINDERS = 'Other protein binders',
  UNKNOWN_FUNCTIONS = 'Unknown functions',
  MF_TERMS = 'Functional descriptions',
}

export enum queryStatuses {
  VALID = 1,
  INVALID = -1,
  NO_QUERY = 0,
}

export enum experimentalValidation {
  CORTEX = 0,
  STRIATUM = 1,
  NGN2 = 2,
  FETAL = 3,
}

export enum experimentalValidationNames {
  FETAL = 'Human fetal brain',
  NGN2 = 'Human iPSC',
  CORTEX = 'Adult mouse cortex',
  STRIATUM = 'Adult mouse striatum',
}
