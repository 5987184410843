
const rawData = `BSG
MCC
PPP2R1A
PRPF6
KIF13B
HSD17B4
FLNA
ROGDI
USP15
MAPRE2
UBA52
APLP2
MYO1B
HNRNPH1
CDIP1
IGSF8
ACTA1
ARRB2
PCNA
DLG2
UBE2D3
MBP
KDM1A
PDLIM7
PDHA1
RAP1GAP
G3BP1
ATP8A2
RALBP1
DCTN2
ATP2B4
UCHL5
AP2A1
RAP1B
LIMA1
VDAC3
ABCC8
ABCA2
NDRG4
SUB1
ARHGAP26
SETDB1
HYOU1
CFLAR
PLCG2
EPB41
FLII
SF1
EGFR
PRMT1
NPM1
TMEM132A
UPF1
PLD3
SLC1A2
CTNNA3
DYNLRB1
TJP2
CRY1
ALK
MGST3
CALU
MAVS
SQSTM1
ITCH
KLC2
RPL23A
BAG4
XPO1
CBX5
SEC23B
MADD
RPA3
CACYBP
CAPNS1
RABGAP1
HSPA8
HERC2
MAPK8
RYR3
SOS1
PCCA
MIB2
PRMT5
STXBP1
MON2
DIAPH1
ARHGEF6
SET
PRKAR1B
SIRPA
STX7
RPS21
ARHGEF11
PHGDH
RPL8
FXR2
RPL4
ACTN1
NCAM2
DOCK5
SNAP25
CDC5L
IQGAP1
PRKG1
AGO2
DYNC1H1
MIB1
PSMC3
PTPN3
UTRN
SNX9
CHN1
KLHL22
HNRNPC
GLS
EIF4A2
ILK
GNAI1
DCTN1
APEX1
GNAQ
STOM
RBFOX1
PKM
NEDD8
PCM1
KIFC3
HPRT1
HSP90AA1
RABEP1
GRIA1
TCP1
RAB11A
GFAP
CUL4B
EIF1B
DOCK7
EDC4
SMARCA5
USP11
MAP4K4
CDC27
ATRX
TJP1
INSR
HMOX2
DLST
EIF3H
CAST
KCND3
RTN1
KLC1
MAPK14
BASP1
PSMC6
MAP1B
STAU1
FEZ1
EIF6
MCM5
ELMO1
SKP1
DDX17
TECR
RBM39
RPS4X
MAGI1
SHC1
FGFR1
ERC2
ANLN
CACNG3
ROBO1
SH3KBP1
PRKACB
RIT2
TF
RYR1
NCK2
BAG3
PIK3CA
LCK
ASNS
GDI1
RANBP2
PRKAR1A
ERBB4
UBR5
AP1B1
RPS27
SBF1
TUBB4B
GNAZ
SBF2
RASGRF1
CD81
ASAP1
CPSF3
HAGH
GRIK2
GPM6B
SDHA
ACACB
UGGT1
TMOD2
UBR4
LAMA3
ATP2C1
CPS1
TRIM28
TTC28
KAZN
LETMD1
ATG5
RC3H1
FN1
FKBP8
PIK3R2
CTNNA1
NAP1L1
ARHGAP33
SEC63
COX4I1
CIT
HNRNPL
DOCK4
RPS8
RPL13A
SMARCD1
UBE2K
ATP1A1
CUL2
PRKDC
ALDOA
FBN1
MAP4
CSE1L
GRIN2A
RPL32
APP
LZTR1
CORO1C
ATP2A2
LARP1
IGF1R
SDF4
LZTS2
GABARAPL2
CDK9
ATP6V1E1
UBE2D2
ASPM
ARF3
NEDD4L
CDC42
EWSR1
HSPH1
HSPA1B
SUPT16H
PRPF8
CCDC88A
TNPO2
TAGLN3
APPL1
GPI
JAK1
SNRPN
RPS14
EEF1B2
HDLBP
SPAG9
GOT1
CHD5
VAV3
CAMK2D
DOCK1
HDGF
PARD3
PRKAA2
HBS1L
MDH1
GART
BCR
EPHA5
PRDX1
TARDBP
NCALD
CKB
DIAPH2
BRINP1
CTSD
DNM3
MAPK8IP1
HUWE1
FAF2
CRK
NEFM
RPL37A
EIF4H
CAPN1
KPNA2
TPM1
GNAS
NRXN3
CYLD
TUBA1C
TUBB2B
CUL7
PSMB1
PIK3C2B
DCLK1
PSMC4
EFTUD2
MAGI2
SLC22A17
CDK16
SIRT7
LIMK1
EIF3A
NLGN1
CRMP1
MACF1
ASIC2
SNX3
MAD1L1
CAPZA2
UNK
COPS6
IQSEC2
ARFGEF2
POLR2E
EIF4A3
UBL4A
RAB9A
CCT2
KIFAP3
TMPO
FBL
MCM6
RAC1
PPP3CB
RPS3
CFL1
NCL
HDAC4
TSG101
COPS5
CD99L2
SOD1
USP32
KCNIP4
ARFGEF1
PCMT1
CNTN5
TUBB4A
DDX1
RNH1
EHD1
TPPP
NISCH
EP300
DPP10
DOCK9
PLCG1
SHANK2
MATR3
YWHAZ
STIP1
GLYR1
SRRM1
MLH1
DDHD2
PPP2R5B
ARF1
PSD
CLINT1
TLN2
UNC5C
REV3L
OTUB1
SNW1
SYT7
CDH12
UBE3A
RET
MYL12B
HSP90AB1
DNM1
UBA6
CUL1
SEC23A
MAPK10
FGFR2
YTHDC2
MRPL28
NDUFS1
NDRG1
ATP13A2
CHMP4B
MTCH1
KSR2
CAMK1D
NCK1
CAD
RABAC1
RUVBL2
HNRNPD
KANK2
ACOT7
EPHA2
ELMO2
RALY
PRPF19
TUBA1B
HECW1
FGFR3
SUPT5H
NCDN
DTNA
HTT
TENM4
PLCB1
KIF5A
CSNK1E
EIF3I
EEF1G
MAGED2
GALNT13
CSNK1A1
TPR
PDGFRB
USP7
KIF1A
UBE3C
SMARCC1
SDCBP
ACADVL
NUDCD3
ARAF
ANKS1A
RAI14
PDIA3
SPTBN4
ILF2
CNOT1
SLIT2
MCM7
VWF
TTC1
PDPK1
SRPK2
ATXN2
CAMK2G
FLT1
SCG3
RAB1B
RAP1GDS1
PTBP1
UCHL1
UQCRC1
AGAP2
BECN1
TGOLN2
RPL15
ACTR1B
EEF2
APBA2
DAB1
ALS2
GNG3
VTI1B
RPL38
ATP1B2
CAND1
BCAR1
ENO1
SF3B2
LGALS3BP
GAK
PC
TFRC
PPP1R12A
ABL1
SUN2
CSNK2A2
GPM6A
GNAI2
HSP90B1
DOCK11
SLC7A14
RDX
ARHGEF12
LIMCH1
RPS10
ADAM11
NEDD4
MYO18A
PI4KA
AGO4
HSPB1
GNB1
VAPA
KCNQ2
YWHAH
PRKCG
CUL3
IPO5
OPTN
CX3CL1
HSPA9
GRIN1
CD44
EPHA7
PTPN4
DDB1
CDK4
CLIP3
KAT2B
WNK1
IPO13
ROBO2
CYB5R3
GDAP1
CDK2
RPL11
TUBB3
RANGAP1
ANXA2
LDHA
CTNNA2
DYNLL1
MET
ILF3
HSPA4
NDRG2
KHDRBS2
PSMD2
SRPK1
PRKCB
FSD1
OXCT1
JUP
LRRC4C
THRAP3
UBC
PARP1
ARRB1
PITHD1
KPNA6
TP53BP1
WWOX
CTNNB1
HNRNPK
KIF3A
PLEKHA7
PRPF40A
NXF1
HNRNPUL1
ATP2B1
STAT1
GAPDH
HECW2
ATP1A3
SVIL
BCLAF1
FARSB
CHD4
PTPN11
OCIAD1
PLCB4
DDX3X
TPM3
MAP2K4
PGRMC1
CADM2
LGALS3
GOPC
ATP4A
AKT1
PCDH9
PTBP3
DNAJC7
GNB5
HSD17B10
CEP170B
SCN8A
ABCE1
MVP
AGO1
ZDHHC17
MPP5
CLCN6
ITSN2
TUBB2A
RPS3A
DPP6
CIAPIN1
DNAJA1
CACNA1G
LRBA
HSPG2
TBC1D5
DSP
HNRNPA3
RPS12
NTRK3
PTN
PCBP1
PTPRC
RPL29
CELF2
ARAP2
MCF2L
MAPK9
RAB5C
FLNB
PRDX2
GNB2
VCAM1
SLC30A9
CASK
CEND1
ABR
REL
CDK17
SPOCK1
RAB6B
FBXO6
SIPA1L1
ANK1
PTPRK
KIAA0513
ICAM5
PSMA3
CDK14
HSCB
RPL12
TSC1
AP3B2
CDC37
HSPA5
ADCYAP1R1
PPIB
FHL2
IQSEC1
UBE2M
PCDHGB1
PTPRN
PDCD6IP
PKD1
PSAP
MAPK1
ANXA7
SORT1
GRAMD1B
MAPK3
CDC42BPA
CLTA
EZR
RTN3
PRPF31
WBP2
CCT3
WDR1
AP2M1
GOLGA2
SYNCRIP
VSNL1
PPP1CC
SFPQ
RPL19
UBQLN1
CBX6
CALM3
NCOR2
STAU2
CTTN
BAG6
XRCC5
MSH2
ABL2
PIK3R1
MATK
ZC3H15
YBX1
DHX15
COX15
LANCL1
HSPD1
UBASH3B
STX1B
OLFM3
APBB1
PPM1H
PLXNB2
HELZ
SMC1A
AP1M1
SYNE2
KIF1B
FLNC
RAB21
SHANK3
PARK7
LIN7B
SLC9A3R1
OBSL1
SEC16A
KSR1
SYT1
TUBG2
PAN2
TBK1
RAB15
PRKAR2B
NEGR1
ATP6AP2
LAMP1
CUL5
TLN1
REEP5
WWP2
HCN1
DNAJA2
TRAF6
HDAC6
SNRNP200
PAX6
USP5
GABBR2
SRSF1
ATP1B3
RAB2A
PPIA
RPS9
RNF31
DHX8
DGKG
GRM1
BRAF
VDAC1
TOMM34
CCT7
POLR2A
TUBA4A
PRKCZ
CD9
NOS2
PIK3C2A
CBL
RUVBL1
SMARCE1
BAIAP2
WASL
STUB1
SORBS1
ITGB5
HNRNPA1
NECAP1
NDRG3
FBXW11
TNRC6C
LGALS1
PRKCD
TOMM20
GRN
FARP1
MAPK6
TUBA1A
TUFM
HDAC2
CPNE6
GAN
AGAP1
GIT2
PSMC1
GSTP1
SORL1
DSTN
PSMD1
LRP1
SFN
ATRN
GAP43
STMN3
TRIM2
SHMT2
AP2A2
EHD3
SP100
AMIGO1
EPHB1
CALR
LRPPRC
TSC22D1
ITPR2
LIG3
NUDC
UBB
HNRNPA0
MAPRE3
DYSF
ABLIM1
HNRNPR
MCM2
NONO
MYLK
CNP
NEO1
PLXNA2
SMARCA4
DPYSL2
ATP6V1A
FUS
SMC3
CAMKK1
SORBS2
LDHB
NCOR1
EIF4A1
ATP9A
SPTBN1
SRRM2
PKP4
LMNA
HGS
GNAI3
PTPN1
ACTN4
DNAJC5
CNTN4
EHMT2
RPA1
SPG7
PRNP
NOP56
DNM2
PSMA1
RPLP1
NEBL
MACROD2
SMARCC2
DOCK3
FLOT1
SPAST
EPB41L2
EPB41L1
MYOF
RPS18
ZC3HAV1
AHCYL1
PCDHAC2
SARM1
ERC1
YWHAB
BARD1
HNRNPF
DST
MYO1C
NDFIP1
CNKSR2
UBQLN2
EIF4B
UBE2I
RNF11
MARK3
SERBP1
ITPR1
KIF3C
KHSRP
STK4
RABGAP1L
COPB1
DDX5
COPS7A
KMT2A
AIFM1
DMTN
ARL8B
USP19
LRRK2
CDH13
PLXNA4
FASN
MYH9
SIRT2
OCRL
CACNA1C
POLR2B
FUNDC1
DICER1
AKT2
BAZ1B
LSAMP
HNRNPU
YWHAQ
RELL2
AP1S1
TCOF1
RPL5
PREPL
XRN1
ANK2
RAB10
RAP1A
MTPN
JPH4
PPP1CB
PDE4DIP
KCND2
ACLY
TRAF4
PDE4D
PABPC4
RICTOR
ACTA2
MCM3
GABRB3
PRDX5
MAP2
TUBB
CDC42BPB
UBA1
GANAB
EMD
PGAM1
TOP2B
RPS6
MAPT
HCCS
PPP1CA
CAPZB
FHL1
SGTB
SULT4A1
TMEM30A
ATXN3
G3BP2
FMR1
U2AF2
RPS13
HYDIN
TSPAN7
TOP2A
ECH1
NRN1
LMO7
DDX21
NF2
RPTOR
CDH18
TOLLIP
RASA1
ARHGEF7
RPL35
EEF1A1
TUBG1
IRS1
RANBP9
PRKCE
HDAC1
RUNDC3A
HERC1
UNC13A
RB1CC1
HRAS
ATM
GLRB
CEP170
WASF1
SPAG16
OFD1
ARNT2
PICALM
PPP2CB
NBEA
POLB
ANKRD28
PPP1R16B
CDK5R1
XRN2
KHDRBS1
MSN
MOV10
MEGF8
RAF1
DYNC1I1
CDH10
SMAD3
AHSA1
MAP1LC3A
PFKP
PPP5C
DDX6
TCF12
GGCT
CSNK2A1
GRB2
EPHB6
RPS16
KCNA2
VAV2
ANKFY1
KPNB1
SLC12A5
RBFOX2
XRCC6
RCN1
P4HB
TRIO
SCN1A
NCAM1
NCKAP1
COPA
PPP3R1
HADHA
VAPB
RBMX
CIRBP
SLC1A3
TRIM27
MYH7
AHNAK`

const entries = rawData.split('\n').sort().map(entry => {
    return {
        genes: entry
    }
})


export const useIntegrated = () => {
    return entries
  }